function card($el) {
	let frame;
	const setProgress = () => {
		const {top, height} = $el.getBoundingClientRect();
		const vh = window.innerHeight;
		const scrollProgress = Math.min(1, Math.max(0, (vh - top) / (height + vh)));
		$el.style.setProperty('--scroll-progress', scrollProgress);
	};

	window.addEventListener('scroll', () => {
		cancelAnimationFrame(frame);
		frame = requestAnimationFrame(setProgress);
	});

	setProgress();
}

document.querySelectorAll('[data-js-scroll-progress]')
	.forEach($el => card($el));
