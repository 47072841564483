import * as THREE from 'three';
import {OrbitControls} from 'three/addons/controls/OrbitControls.js';
import {vertexShader, fragmentShader} from '../vendor/shaders.js';

function init($el) {
	const $container = $el.querySelector('.js-header-object');
	const $menu = $el.querySelector('.menu__bar');
	const fov = 40;
	const start = Date.now();

	const scene = new THREE.Scene();
	const camera = new THREE.PerspectiveCamera(fov, window.innerWidth / window.innerHeight, 1, 10000);
	camera.position.z = 100;
	camera.target = new THREE.Vector3(0, 0, 0);

	scene.add(camera);

	const panoTexture = new THREE.TextureLoader().load($container.dataset.panoTexture);

	const material = new THREE.ShaderMaterial({
		uniforms: {
			tShine: {type: 't', value: panoTexture},
			time: {type: 'f', value: 0},
			weight: {type: 'f', value: 0},
		},
		vertexShader,
		fragmentShader,
	});

	const mesh = new THREE.Mesh(new THREE.IcosahedronGeometry(20, 7), material);
	scene.add(mesh);

	const renderer = new THREE.WebGLRenderer({antialias: true});
	renderer.setPixelRatio(window.devicePixelRatio);
	renderer.autoClear = false;

	$container.appendChild(renderer.domElement);

	const controls = new OrbitControls(camera, renderer.domElement);
	controls.enableZoom = false;
	controls.enablePan = false;

	function setRenderSize() {
		const headerSpec = $el.getBoundingClientRect();
		const menuSpec = $menu.getBoundingClientRect();
		$el.style.setProperty('--_menu-offset', `${menuSpec.height}px`);

		renderer.setSize(headerSpec.width, headerSpec.height * 1.25);
		camera.aspect = headerSpec.width / (headerSpec.height * 1.25);
		camera.updateProjectionMatrix();
	}

	window.addEventListener('resize', setRenderSize);
	setRenderSize();

	function render() {
		material.uniforms.time.value = 0.00010 * (Date.now() - start);
		material.uniforms.weight.value = 10.0 * (0.5 + (0.5 * Math.sin(0.00025 * (Date.now() - start))));
		//	Material.uniforms[ 'weight' ].value = 10.0;

		renderer.render(scene, camera);
		requestAnimationFrame(render);
	}

	render();
}

document.querySelectorAll('.js-header').forEach($el => init($el));
