import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';

const MENU_BREAKPOINT = '(min-width: 30em)'; // >= 480px
const KEYCODE_TAB = 9;

class Menu {
	constructor($el) {
		if (!$el) {
			return;
		}

		this.$el = $el;
		this.$toggle = this.$el.querySelector('.js-menu__toggle');
		this.$popup = this.$el.querySelector('.js-menu__popup');
		this.$scroller = this.$el.querySelector('.js-menu__scroller');
		this._focusTrapCallback = this.getFocusTrapCallback();
		this.$$links = this.$el.querySelectorAll('.js-menu__link');
		this.$scrollProgressTarget = document.querySelector(this.$el.dataset.scrollProgressTarget);
		this.$header = this.$el.closest('.js-header');

		this._closeCallback = e => {
			if (this.$el.contains(e.target)) {
				// Do not close menu when clicking inside
				return;
			}

			this.toggle(false);
		};

		this.$toggle.addEventListener('click', this.toggle.bind(this));

		this.mediaQuery = matchMedia(MENU_BREAKPOINT);
		this.mediaQuery.addEventListener('change', query => this.setDesktopMode(query.matches));
		this.setDesktopMode(this.mediaQuery.matches);

		this.$el.addEventListener('keydown', e => {
			if (!this.isOpen) {
				return;
			}

			if (e.key === 'Escape') {
				this.toggle(false);
			}
		});

		// Close when link is clicked
		this.$popup.addEventListener('click', e => {
			const $link = e.target.closest('a');
			if (!$link) {
				return;
			}

			this.toggle(false);
		});

		// Scroll progress tracking
		let frame;
		let lastLogoScale = null;
		let lastLogoTranslate = null;
		let lastProgress = null;

		const fn = () => {
			const {height} = this.$scrollProgressTarget.getBoundingClientRect();
			const progress = Math.min(1, window.scrollY / height);
			const logoScale = Math.min(1, Math.max((1 - progress) * 1.15, 0));
			const logoTranslate = '0 -' + (progress * 15) + '%';

			if (lastLogoScale === null || lastLogoScale !== logoScale) {
				this.$el.style.setProperty('--_logo-scale', logoScale);
				lastLogoScale = logoScale;
			}

			if (lastLogoTranslate === null || lastLogoTranslate !== logoTranslate) {
				this.$el.style.setProperty('--_logo-translate', logoTranslate);
				lastLogoTranslate = logoTranslate;
			}

			if (lastProgress !== null || progress !== lastProgress) {
				this.$header.style.setProperty('--_progress', progress);
				lastProgress = progress;
			}
		};

		window.addEventListener('scroll', () => {
			cancelAnimationFrame(frame);
			frame = requestAnimationFrame(fn);
		});
		fn();
	}

	setDesktopMode(state) {
		if (state) {
			this.toggle(false);
		}

		this.$el.classList.add('no-transitions');

		requestAnimationFrame(() => {
			this.$el.classList.remove('no-transitions');
		});
	}

	get isOpen() {
		return this.$toggle.getAttribute('aria-expanded') === 'true';
	}

	getFocusableElements() {
		// Source: https://github.com/gdkraus/accessible-modal-dialog/blob/master/modal-window.js#L38
		return [
			...this.$popup.querySelectorAll(
				[
					'a[href]',
					'area[href]',
					'input:not([disabled])',
					'select:not([disabled])',
					'textarea:not([disabled])',
					'button:not([disabled])',
					'iframe',
					'object',
					'embed',
					'*[tabindex]',
					'*[contenteditable]',
				].join(', '),
			),
		].filter($item => $item.offsetParent !== null); // Filter out hidden elements
	}

	getFocusTrapCallback() {
		return e => {
			if (e.key !== 'Tab' || e.keyCode !== KEYCODE_TAB) {
				return;
			}

			const $$focusable = this.getFocusableElements();
			const $firstFocusable = $$focusable[0];
			const $lastFocusable = $$focusable[$$focusable.length - 1];
			const currentIndex = [...$$focusable].findIndex(
				$item => $item === document.activeElement,
			);

			if (currentIndex === -1) {
				return;
			}

			if (e.shiftKey) {
				if (document.activeElement === $firstFocusable) {
					e.preventDefault();
					$lastFocusable.focus();
				} else if (currentIndex !== -1) {
					e.preventDefault();
					$$focusable[currentIndex - 1].focus();
				}
			} else if (document.activeElement === $lastFocusable) {
				e.preventDefault();
				$firstFocusable.focus();
			} else if (currentIndex !== -1) {
				e.preventDefault();
				$$focusable[currentIndex + 1].focus();
			}
		};
	}

	toggle(force = null) {
		const oldState = this.isOpen;
		const newState = typeof force === 'boolean' ? force : !this.isOpen;

		if (newState === oldState) {
			return;
		}

		this.$toggle.setAttribute('aria-expanded', newState);
		this.$el.dataset.isOpen = newState.toString();

		if (newState) { // Open
			window.scrollTo(0, 0);
			window.addEventListener('click', this._closeCallback);

			// Set focus to first link or if not links are there to first
			// focusable element (i.e. logo link)
			if (this.$$links.length > 0) {
				this.$$links[0].focus();
			} else if (this.getFocusableElements().length > 0) {
				this.getFocusableElements()[0]?.focus();
			}

			window.addEventListener('keydown', this._focusTrapCallback);
			disableBodyScroll(this.$popup, {reserveScrollBarGap: true});
		} else { // Close
			window.removeEventListener('keydown', this._focusTrapCallback);
			window.removeEventListener('click', this._closeCallback);
			enableBodyScroll(this.$popup);
			this.$toggle.focus();
		}
	}
}

export default new Menu(document.querySelector('.js-menu'));
