function card($el) {
	$el.addEventListener('mousemove', function (e) {
		const rect = this.getBoundingClientRect();
		// This.style.setProperty('--x', (e.clientX - rect.left) / rect.width);
		// this.style.setProperty('--y', (e.clientY - rect.top) / rect.height);
		this.style.setProperty('--x', ((e.clientX - rect.left) - (rect.width / 2)));
		this.style.setProperty('--y', -1 * ((e.clientY - rect.top) - (rect.height / 2)));
	});
}

document.querySelectorAll('[data-js-card]')
	.forEach($el => card($el));
